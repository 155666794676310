/* Makes delete rule-remove button [X] right-aligned. */
/* uncomment these lines to also right-align group-delete-button */
/* .queryBuilder .ruleGroup-addGroup + button.ruleGroup-cloneGroup,
.queryBuilder .ruleGroup-addGroup + button.ruleGroup-lock,
.queryBuilder .ruleGroup-addGroup + button.ruleGroup-remove,  */
.queryBuilder .chakra-select__wrapper + button.rule-cloneRule,
.queryBuilder .chakra-select__wrapper + button.rule-lock,
.queryBuilder .chakra-select__wrapper + button.rule-remove,
.queryBuilder .control + button.rule-cloneRule,
.queryBuilder .control + button.rule-lock,
.queryBuilder .control + button.rule-remove,
.queryBuilder .rule-operators + button.rule-cloneRule,
.queryBuilder .rule-operators + button.rule-lock,
.queryBuilder .rule-operators + button.rule-remove,
.queryBuilder .rule-value + button.rule-cloneRule,
.queryBuilder .rule-value + button.rule-lock,
.queryBuilder .rule-value + button.rule-remove {
  margin-left: auto;
}
